@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background: #202124;
}